import React, { useState, useEffect } from 'react';
import Layout from '@components/layout/layout.jsx'
import DocumentHeadMeta from '@components/utility/SEO.jsx'
import { StaticImage, getImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import './styles.scss'
//Components
import SubNav from '@components/subNav/subNav.jsx'
import Hero from '@components/hero/hero.jsx'
import Portrait from '@components/portrait/portrait.jsx'


// markup
const AboutLNPage = ({ data }) => {
  const MoniquePortrait = getImage(data.portraitMonique)
  const DinaPortrait = getImage(data.portraitDina)
  const HeroImage = getImage(data.hero)

  useEffect(() => {
      document.querySelector('body').addEventListener('click', (e) => {
        document.getElementById(e.target?.href?.split("#")[1])?.focus();
        }
      )
  }, []);


  return (
    <Layout addedClass="page--about-ln">
      <DocumentHeadMeta title='What is Lupus Nephritis? | ALL IN&reg; for Lupus Nephritis' description='Lupus nephritis is an inflammation of the kidneys caused by systemic lupus erythematosus (SLE). Learn more about lupus nephritis, who is affected; and signs, symptoms, and classes of the disease.' canonicalURL='https://www.allinforlupusnephritis.com/about-lupus-nephritis' />
      {/* Bind this to Navbar? */}
      <SubNav>
        <AnchorLink to="/about-lupus-nephritis#What-is-LN">What is lupus nephritis?</AnchorLink>
        <AnchorLink to="/about-lupus-nephritis#Complications">Complications</AnchorLink>
        <AnchorLink to="/about-lupus-nephritis#Affected-by-LN">Who is Affected?</AnchorLink>
        <AnchorLink to="/about-lupus-nephritis#Signs-of-LN">Signs, Symptoms and Classes</AnchorLink>
      </SubNav>
      <Hero heroImage={HeroImage} imgAlt={""}>
            <h1>About Lupus Nephritis</h1>
            <p><strong>Lupus nephritis is not as uncommon as you may think, and can lead to serious complications if left untreated.</strong></p>
            <Link to="/resources" className="cta--body cta--purple">Resources</Link>
      </Hero>
      <section className="bg-white">
        <div className="container container--narrow">
          <h2>The Role of SLE</h2>
          <p className="text-left">Systemic lupus erythematosus (SLE) or lupus is a chronic, complex, and often disabling autoimmune disorder in which the body’s immune system (which fights off bacteria and viruses) attacks the body’s own cells, tissues, and organs.  Lupus can affect many parts of the body including the joints, skin, brain, heart, lungs, and kidneys. When it affects the kidneys, it’s called lupus nephritis. </p>
        </div>

      </section>
      <section tabIndex="-1" id="What-is-LN" className="bg-light-blue ">
        <div className="container container--split container--what-is-ln">
          <div className="split-block split-block--50"><StaticImage src="../../images/about-ln/what-is-ln.png" className="" quality="80" placeholder="NONE" alt="" width={371} ></StaticImage></div>          
          <div className="split-block split-block--50">
            <h2>What is lupus nephritis (LN)?</h2>
            <p>Lupus nephritis is a rare disease—but it's one of the most common and serious complications of lupus, affecting about 50% of&nbsp;people living with it. Lupus nephritis can lead to irreversible kidney damage or even kidney failure—which may require dialysis or a kidney transplant.</p>
            <p>It's critically important to catch lupus nephritis early. Early diagnosis and treatment may help prevent irreversible kidney damage and other serious complications.</p>
          </div>      
        </div>
        <div tabIndex="-1" id="Complications" className="container bg-purple">
          <h2>Know your risks&ndash;complications of lupus nephritis include:</h2>
          <div className="container--split">
            <div className="split-block--50">
              <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/about-ln/icon-blood-pressure.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text"><p className="title"><strong className="semi-bold">Increased blood pressure</strong></p></div>
              </div>
              <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/about-ln/icon-cardiovascular-complications.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title"><strong className="semi-bold">Cardiovascular complications</strong></p>
                  <p className="description">Includes risk of heart attacks and strokes</p>
                </div>
              </div>
              <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/about-ln/icon-kidney-disease.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title"><strong className="semi-bold">Chronic kidney disease</strong></p>
                  <p className="description">Happens when the kidneys are slowly damaged over time and can't filter blood the way they should</p>
                </div>
              </div>
              <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/about-ln/icon-kidney-scarring.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title"><strong className="semi-bold">Scarring of the kidneys</strong></p>
                  <p className="description">Permanent damage that results from the disease progressing</p>
                </div>
              </div>
            </div>
            <div className="split-block--50">
              <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/about-ln/icon-kidney-failure.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title"><strong className="semi-bold">Kidney failure, also known as end&#8209;stage kidney disease (ESKD) or end&#8209;stage renal disease (ESRD)</strong></p>
                  <p className="description">Up to 30% of&nbsp;people who have lupus nephritis will experience kidney failure</p>  
                  <p className="description">Kidney function is measured by glomerular filtration rate (GFR). As kidney disease worsens, the number goes down.  A GFR of 60 or above is normal; any lower may indicate kidney disease. If your GFR reaches 15 or lower, that may mean kidney failure which can result in the need for dialysis or a transplant</p>  
                </div>
              </div>
              <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/about-ln/icon-treatment.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title"><strong className="semi-bold">Treatment and/or steroid-related complications</strong></p>
                  <p className="description">High doses of steroids may increase the risk for adverse events, including irreversible organ damage</p>  
                </div>
              </div>
            </div>
          </div>
            <p className="foot-note">Every person and every situation is different. It's important to speak with a doctor about your risk and a treatment plan that may be appropriate for you.</p>
        </div>
      </section>
      <section className="bg-white">
        <div className="container container--narrow">
          <h2>Early diagnosis and treatment can help prevent irreversible kidney damage and other serious complications.</h2>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <Portrait name="Monique" description="diagnosed with lupus nephritis in 2010" imgSrc={MoniquePortrait} imgAlt=""></Portrait>
          <p>"There’s still no cure for lupus nephritis, this is really important to know. That’s why active surveillance and early detection is key, along with self-management. Now is the time to check in on your kidney health."</p>
        </div>
      </section>
      <section className="bg-white">
        <div className="container">
          <StaticImage src="../../images/stay-connected-icon.png" className="" quality="90" placeholder="NONE" alt="" width={216}></StaticImage>
          <h2>Get help managing lupus nephritis</h2>
          <p className="less-margin">Sign up to receive information and resources, including your Lupus Nephritis Awareness Kit.</p>
          <Link to="/register" className="cta--purple cta--body">Register now</Link>
        </div>
      </section>
      <section tabIndex="-1" id="Affected-by-LN" className="bg-light-purple">
        <div className="container container--narrow">
          <h2>Who is affected?</h2>
          <p className="description">Lupus nephritis is one of the most common complications of lupus. In fact, about 1 in 3&nbsp;people are diagnosed with it at the time of their lupus diagnosis, and about half of&nbsp;people with lupus may go on to develop it later. Even though lupus nephritis typically begins in&nbsp;people around 20-30&nbsp;years of age, the number of&nbsp;people that get diagnosed each year tends to continue to increase with age. As far as gender, it's more common among women, but tends to affect men more severely.</p>
          <div className="split-block__item split-block__item--stats">
            <div className="icon"><StaticImage src="../../images/about-ln/stat-1.png" className="" quality="100" placeholder="NONE" alt="" width={54} ></StaticImage></div>
            <p className="title">Lupus nephritis is more common in some racial and ethnic groups than others</p>
          </div>
          <div className="split-block__item split-block__item--stats">
            <div className="icon"><StaticImage src="../../images/about-ln/stat-2.png" className="" quality="100" placeholder="NONE" alt="" width={87} ></StaticImage></div>
            <p className="title">4x more likely for&nbsp;people of African and Asian descent<sup>&alpha;</sup></p>
          </div>
          <div className="split-block__item split-block__item--stats">
            <div className="icon"><StaticImage src="../../images/about-ln/stat-3.png" className="" quality="100" placeholder="NONE" alt="" width={85} ></StaticImage></div>
            <p className="title">2x more likely for Hispanic and Native&nbsp;peoples<sup>&alpha;</sup></p>
          </div>
          <p className="reference"><sup>&alpha;</sup>Compared to non-Hispanic white&nbsp;people</p>
        </div>
      </section>
      <section className="bg-light-blue">
        <div className="container container--narrow">
          <StaticImage src="../../images/about-ln/start-discussion.png" className="" quality="85" placeholder="NONE" alt="" width={309} ></StaticImage>
          <h2>Start the discussion early.</h2>
          <p className="less-margin">Staying on top of your appointments and getting the most out of your time with your doctor is crucial to catching lupus nephritis as early as possible. Use this guide to help ensure you're asking the right questions.</p>
          <Link to="/resources" className="cta--purple cta--body">Download guide</Link>
        </div>
      </section>
      <section tabIndex="-1" id="Signs-of-LN" className="bg-white ">
        <div className="container">
          <h2>Signs, symptoms, and classes of lupus nephritis</h2>
          <p className="text-left">Symptoms of lupus nephritis can be subtle, inconsistent, or hard to recognize—that's why frequent testing is so important. <Link to="/diagnosis-and-treatment" className="link--purple">See the types of tests used to diagnose LN</Link></p>
          <div className="container container--narrow container--split symptoms-icons">
            <div className="split-block--40">
              <div className="split-block__item">
                <div className="icon"><StaticImage src="../../images/about-ln/icon-blue-swelling.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text"><p className="title">Swelling <br />(often in the feet, ankles, or legs)</p></div>
              </div>
              {/* TODO CHANGE ICON */}
              <div className="split-block__item">
                <div className="icon"><StaticImage src="../../images/about-ln/icon-blue-blood-pressure.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title">Weight gain</p>
                </div>
              </div>
            </div>
            <div className="split-block--40">
              <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/about-ln/icon-blue-frothy-urine.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title">Foamy or frothy urine <br />(small bubbles in urine)</p>
                </div>
              </div>
              <div className="split-block__item">
                <div className="icon"><StaticImage src="../../images/about-ln/icon-blue-frequent-urination.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title">Frequent urination <br />(especially at night)</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container container--narrow description">
            <p>Lupus nephritis is a serious disease with severe complications. Even if you don't see symptoms, you may have permanent kidney damage. That's why it's important to prioritize regular doctor visits—even when symptoms are subtle or unnoticeable. Routine testing by a doctor is key to helping you stay ahead of potential long-term damage and complications.</p>
          </div>
          <div className="container--split checklist-row">
            <div className="split-block--50">
              <StaticImage src="../../images/about-ln/signs-and-symptoms.png" className="split-item__icon" quality="90" placeholder="NONE" alt="" width={459}></StaticImage>
            </div>
            <div className="split-block--50">
              <div className="checklist-row__list">
                <h3 className="title">Clinical signs that require testing include:</h3>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" loading="eager" className="" quality="100" placeholder="NONE" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p>High levels of protein in the urine (known as proteinuria) that show up in a clinical laboratory urine test</p>
                  </div>
                </div>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" loading="eager" className="" quality="100" placeholder="NONE" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p>Blood in the urine (known as hematuria)</p>
                  </div>
                </div>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" loading="eager" className="" quality="100" placeholder="none" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p>Increased blood pressure</p>
                  </div>
                </div>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" loading="eager" className="" quality="100" placeholder="none" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p>Inflammation or scarring of the kidneys, as confirmed through a kidney biopsy</p>
                  </div>
                </div>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" loading="eager" className="" quality="100" placeholder="none" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p>Unbalanced levels of electrolytes, or minerals in the body, measured through a blood test</p>
                  </div>
                </div>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" loading="eager" className="" quality="100" placeholder="none" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p>Slower rate of blood filtration through the kidneys, known as glomerular filtration rate, measured through a blood test</p>
                  </div>
                </div>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" loading="eager" className="" quality="100" placeholder="none" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p>Increase in creatinine, a waste product that results from the normal breakdown of muscles, measured through a blood test</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-purple">
        <div className="container container--narrow">
          <StaticImage src="../../images/about-ln/key-feature.png" className="" quality="85" placeholder="NONE" alt="" width={105} ></StaticImage>
          <h2>The key feature of lupus nephritis</h2>
          <p className="less-margin">Higher than normal levels of protein in the urine <strong>(&gt;0.5&nbsp;grams per day)</strong>, a condition known&nbsp;as&nbsp;<strong>proteinuria</strong>.</p>
        </div>
      </section>
      <section className="bg-white">
        <div className="container container--narrow">
          <h2>Classes of lupus nephritis</h2>
          <p className="text-left">A rheumatologist or nephrologist will use the results of a kidney biopsy to figure out how lupus nephritis has affected the kidneys. This information can also help doctors decide on an appropriate treatment plan.</p>
        </div>
        <div className="container">
          <div className="class-description-container">
            <div className="class-description-container__item">
              <div className="class-column title"><strong>Class</strong></div>
              <div className="description-column title"><strong>Description</strong></div>
            </div>
            <div className="class-description-container__item">
              <div className="class-column">
                <p className="class-number">Class I</p>
                <p className="class-name">Minimal mesangial lupus nephritis</p>
              </div>
              <div className="description-column">
                <p className="class-description">Minimal kidney involvement</p>
              </div>
            </div>
            <div className="class-description-container__item">
              <div className="class-column">
                <p className="class-number">Class II</p>
                <p className="class-name">Mesangial proliferative lupus nephritis</p>
              </div>
              <div className="description-column">
                <p className="class-description">Some evidence of inflammation in limited areas of the kidneys</p>
              </div>
            </div>
            <div className="class-description-container__item">
              <div className="class-column">
                <p className="class-number">Class III</p>
                <p className="class-name">Focal lupus nephritis</p>
              </div>
              <div className="description-column">
                <p className="class-description">Involvement of less than half of the network of small blood vessels in the kidneys</p>
              </div>
            </div>
            <div className="class-description-container__item">
              <div className="class-column">
                <p className="class-number">Class IV</p>
                <p className="class-name">Diffuse proliferative nephritis</p>
              </div>
              <div className="description-column">
                <p className="class-description">Involvement of more than half of the network of small blood vessels in the kidneys</p>
              </div>
            </div>
            <div className="class-description-container__item">
              <div className="class-column">
                <p className="class-number">Class V</p>
                <p className="class-name">Membranous lupus nephritis </p>
              </div>
              <div className="description-column">
                <p className="class-description">Characterized by immune deposits found around the network of small blood vessels</p>
                <p className="class-description">Different from other forms of lupus nephritis</p>
              </div>
            </div>
            <div className="class-description-container__item">
              <div className="class-column">
                <p className="class-number">Class VI</p>
                <p className="class-name">Advanced sclerotic lupus nephritis </p>
              </div>
              <div className="description-column">
                <p className="class-description">Damage to more than 90% of the network of small blood vessels in the kidneys</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="container container--narrow">
          <Portrait name="Dina" description="Diagnosed with lupus nephritis in 1994"  imgSrc={DinaPortrait} imgAlt=""></Portrait>
          <p className="text--purple">"The newly diagnosed need to know it is possible to live a full, happy, and meaningful life with lupus nephritis. It is not a death sentence."</p>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--split">
          <div className="split-item">
            <StaticImage src="../../images/kit-icon.png" className="split-item__icon" quality="90" placeholder="NONE" alt="Image of ALL IN&reg; Lupus Nephritis Awareness Kit" width={214}></StaticImage>
            <h2>Sign up to receive your ALL&nbsp;IN&reg; Lupus Nephritis Awareness Kit.</h2>
            <p>The kit includes resources to help&nbsp;people living with lupus or lupus nephritis better understand and manage the disease.</p>
            <Link to="/register" className="cta--purple cta--body">Get Your Kit</Link>
          </div>
          <div className="divider"></div>
          <div className="split-item">
            <StaticImage src="../../images/stay-connected-icon.png" className="split-item__icon"  quality="90" placeholder="none" alt="" width={216}></StaticImage>
            <h2>Want to learn more about your diagnosis?</h2>
            <p>This discussion guide includes helpful questions to ask at your next doctor's appointment.</p>
            <Link to="/resources" className="cta--purple cta--body">Download guide</Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutLNPage

export const pageQuery = graphql`
  query AboutLNQuery {
    portraitMonique: file(relativePath: {eq: "portrait-Monique.png"}) {
      childImageSharp {
        gatsbyImageData(height:240)
      }
    }
    portraitDina: file(relativePath: {eq: "portrait-Dina.png"}) {
      childImageSharp {
        gatsbyImageData(height:240)
      }
    }
    hero: file(relativePath: {eq: "about-ln/hero-about-ln.png"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
  }
`
